import React from "react"
import {Link} from "react-router-dom"
import numeral from "numeral"
import axios from "axios"
import qs from "qs"
import moment from "moment"
import {Table, Icon, message, Input, Button} from "antd"
import Highlighter from "react-highlight-words"
import {status, apiUrl} from "../../consts"
import FormWebsite from "./FormWebsite"
import FormQuatation from "./FormQuatation"
import FormInvoice from "./FormInvoice"
import FormReceive from "./FormReceive"

const now = moment(new Date())

class Website extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            currentData: {},
            customers: [],
            dataSource: [],
            visibleWebsite: false,
            visibleQuatation: false,
            visibleInvoice: false,
            visibleReceive: false,
            action: "edit",
            columns: [
                {
                    title: "Action",
                    dataIndext: "id",
                    key: "id",
                    render: (text) => {
                        return (
                            <React.Fragment>
                                <Icon type="form" onClick={(e) => this.modalEditWebsite(text)} />
                                <Icon type="delete" onClick={(e) => this.delete(text)} />
                            </React.Fragment>
                        )
                    },
                },
                {
                    title: "Status",
                    dataIndex: "status",
                    key: "status",
                    render: (text) => {
                        return (
                            <span className={"status " + text}>
                                <Icon type="fire" />
                                <span>{status[text]}</span>
                            </span>
                        )
                    },
                    filters: [
                        {
                            text: "Open",
                            value: "open",
                        },
                        {
                            text: "Burning",
                            value: "burning",
                        },
                        {
                            text: "Waiting",
                            value: "waiting",
                        },
                        {
                            text: "Feedback",
                            value: "feedback",
                        },
                        {
                            text: "More req.",
                            value: "more req.",
                        },
                        {
                            text: "Close",
                            value: "close",
                        },
                        {
                            text: "Failed",
                            value: "failed",
                        },
                        {
                            text: "Service",
                            value: "service",
                        },
                        {
                            text: "Canceled",
                            value: "canceled",
                        },
                    ],
                    defaultFilteredValue: ['open', 'burning', 'service'],
                    onFilter: (value, record) => record.status.indexOf(value) === 0,
                    editable: true,
                },
                {
                    title: "Website",
                    dataIndex: "website",
                    key: "website",
                    render: (text, record) => {
                        if (record.domain) {
                            return (
                                <a href={"http://" + record.domain} target="_blank" rel="noopener noreferrer">
                                    {text}
                                </a>
                            )
                        } else {
                            return text
                        }
                    },
                    editable: true,
                    ...this.getColumnSearchProps("website"),
                },
                {
                    title: "Customer",
                    dataIndex: "customer",
                    key: "customer",
                    render: (record) => {
                        return (
                            <React.Fragment>
                                <Link to={"/customer/" + record.id}>{record.name}</Link>
                            </React.Fragment>
                        )
                    },
                    filters: [
                        {
                            text: "พี่หน่อย",
                            value: 2,
                        }
                    ],
                    onFilter: (value, record) => record.customers_id.indexOf(value) === 0,
                },
                {
                    title: "Transaction",
                    children: [
                        {
                            title: "Quatation",
                            dataIndex: "quatations",
                            key: "quatations",
                            render: (text, record) => {
                                let response = []
                                if (text && text[0]) {
                                    response = text.map((value, key) => {
                                        return (
                                            <div className={value.term_of_payment === "1" ? "transaction-action twoRow" : "transaction-action"} key={key}>
                                                <div className="qt">
                                                    <button onClick={(e) => this.modalEditQuatation(value)} key={key}>
                                                        {/* {getQuatationNumber(value.date, value.quatation_number)} */}
                                                        {moment(value.date).format("DD MMM YYYY")}
                                                    </button>
                                                    <Link to={"/quatation/" + value.id} target="_blank">
                                                        <Icon type="printer" />
                                                    </Link>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                                response.push(
                                    <div className="transaction-action full-width" key="23985u9">
                                        <div className="qt">
                                            <button key={response.length} onClick={(e) => this.modalEditQuatation(record)}>
                                                <Icon type="plus" className="dimb mr5" /> <span className="dimb">Quatation</span>
                                            </button>
                                        </div>
                                    </div>
                                )
                                return response
                            },
                        },
                        {
                            title: "Invoice",
                            dataIndex: "quatations",
                            key: "invoice",
                            render: (text, record) => {
                                let response = []
                                if (text && text[0]) {
                                    response = text.map((value, key) => {
                                        const prefixNumber = value.term_of_payment === "1" ? 2 : 1
                                        let num = prefixNumber - value.invoices.length
                                        num = num > 0 ? Array.apply(null, {length: num}) : []
                                        return (
                                            <div className={value.term_of_payment === "1" ? "transaction-action inv many" : "transaction-action"} key={key}>
                                                <div className="invoice">
                                                    {value.invoices[0] &&
                                                        value.invoices.map((row, index) => {
                                                            return (
                                                                <div key={index}>
                                                                    <div className="item">
                                                                        <button onClick={(e) => this.modalEditInvoice({quatation: value, website: record, invoice: row})} key={key}>
                                                                            {/* {getInvoiceNumber(row.date, row.invoice_number)} */}
                                                                            {moment(row.date).format("DD MMM YYYY")}
                                                                        </button>
                                                                        <Link to={"/invoice/" + value.id + "/" + row.id} target="_blank">
                                                                            <Icon type="printer" />
                                                                        </Link>
                                                                    </div>
                                                                </div>
                                                            )
                                                        })}

                                                    {num.map((row, index) => {
                                                        return (
                                                            <div key={index} className="new">
                                                                <button onClick={(e) => this.modalEditInvoice({quatation: value, website: record})}>
                                                                    <Icon type="plus" className="dimb mr5" />
                                                                    <span>New invoice</span>
                                                                </button>
                                                            </div>
                                                        )
                                                    })}
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                                return response
                            },
                        },
                        {
                            title: "Receive",
                            dataIndex: "quatations",
                            key: "receive",
                            render: (text, record) => {
                                let response = []
                                if (text && text[0]) {
                                    response = text.map((value, key) => {
                                        return (
                                            <div className={value.term_of_payment === "1" ? "transaction-action many" : "transaction-action"} key={key}>
                                                <div className="receive">
                                                    {value.invoices[0] &&
                                                        value.invoices.map((row, index) => {
                                                            if (!row.receive_date) {
                                                                return (
                                                                    <div key={index} className="new">
                                                                        <button onClick={(e) => this.modalEditReceive({quatation: value, website: record, invoice: row})}>
                                                                            <Icon type="plus" className="dimb mr5" />
                                                                            <span>New receive</span>
                                                                        </button>
                                                                    </div>
                                                                )
                                                            } else {
                                                                return (
                                                                    <div key={index}>
                                                                        <div className="item">
                                                                            <button onClick={(e) => this.modalEditReceive({quatation: value, website: record, invoice: row})} key={key}>
                                                                                {/* {getReceiveNumber(row.receive_date, row.invoice_number)} */}
                                                                                {row.receive_not_sure_date === "1" ? "-" : moment(row.receive_date).format("DD MMM YYYY")}
                                                                            </button>
                                                                            <Link to={"/receive/" + value.id + "/" + row.id} target="_blank">
                                                                                <Icon type="printer" />
                                                                            </Link>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            }
                                                        })}
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                                return response
                            },
                        },
                        {
                            title: "Cost estimate",
                            dataIndex: "quatations",
                            key: "net",
                            render: (text) => {
                                return text.map((value, key) => {
                                    return (
                                        <p key={key} className={value.term_of_payment === "1" ? "net many" : "net"}>
                                            {numeral(value.net).format("0,0")}
                                        </p>
                                    )
                                })
                            },
                        },
                        {
                            title: "Paid",
                            dataIndex: "quatations",
                            key: "paid",
                            render: (text) => {
                                return text.map((value, key) => {
                                    let net = 0
                                    let num = 0
                                    if (value.invoices[0]) {
                                        value.invoices.forEach((v, k) => {
                                            if (v.receive_date) {
                                                num = num + 1
                                            }
                                        })

                                        if (value.term_of_payment === "1" && num === 2) {
                                            net = value.net
                                        } else if (value.term_of_payment === "1" && num === 1) {
                                            net = value.net / 2
                                        } else if (value.term_of_payment === "1" && num === 0) {
                                            net = 0
                                        } else if (value.term_of_payment !== "1" && num === 1) {
                                            net = value.net
                                        } else if (value.term_of_payment !== "1" && num === 0) {
                                            net = 0
                                        }
                                    }
                                    return (
                                        <p key={key} className={value.term_of_payment === "1" ? "net many" : "net"}>
                                            {numeral(net).format("0,0")}
                                        </p>
                                    )
                                })
                            },
                        },
                        {
                            title: "Owe",
                            dataIndex: "quatations",
                            key: "own",
                            render: (text) => {
                                return text.map((value, key) => {
                                    let net = 0
                                    let num = 0
                                    if (value.invoices[0]) {
                                        value.invoices.forEach((v, k) => {
                                            if (v.receive_date) {
                                                num = num + 1
                                            }
                                        })
                                        if (value.term_of_payment === "1" && num === 2) {
                                            net = value.net
                                        } else if (value.term_of_payment === "1" && num === 1) {
                                            net = value.net / 2
                                        } else if (value.term_of_payment === "1" && num === 0) {
                                            net = 0
                                        } else if (value.term_of_payment !== "1" && num === 1) {
                                            net = value.net
                                        } else if (value.term_of_payment !== "1" && num === 0) {
                                            net = 0
                                        }
                                    }
                                    return (
                                        <p key={key} className={value.term_of_payment === "1" ? "net many" : "net"}>
                                            {numeral(value.net - net).format("0,0")}
                                        </p>
                                    )
                                })
                            },
                        },
                    ],
                },
                {
                    title: "Expired",
                    dataIndex: "expired",
                    children: [
                        {
                            title: "Hosting",
                            dataIndex: "host_expired",
                            key: "host_expired",
                            render: (text, record) => {
                                const host_expired = moment(record.host_expired, "YYYY-MM-DD")
                                const hostExpireDays = parseFloat(numeral(moment.duration(host_expired.diff(now)).asDays()).format("0.0"))
                                const hasDate = record.host_expired && hostExpireDays
                                return (
                                    <Link target="_blank" to={"/hosting/" + record.id} className={hostExpireDays < 0 ? "expired expire" : hasDate && hostExpireDays < 61 ? "coming expire" : "normal expire"}>
                                        {hasDate ? hostExpireDays + " days" : "-"}
                                    </Link>
                                )
                            },
                        },
                        {
                            title: "Domain",
                            dataIndex: "domain_expired",
                            key: "domain_expired",
                            render: (text, record) => {
                                const domain_expired = moment(record.domain_expired, "YYYY-MM-DD")
                                const hostExpireDays = parseFloat(numeral(moment.duration(domain_expired.diff(now)).asDays()).format("0.0"))
                                const hasDate = record.domain_expired && hostExpireDays
                                return (
                                    <Link target="_blank" to={"/hosting/" + record.id} className={hostExpireDays < 0 ? "expired expire" : hasDate && hostExpireDays < 61 ? "coming expire" : "normal expire"}>
                                        {hasDate ? hostExpireDays + " days" : "-"}
                                    </Link>
                                )
                            },
                        },
                    ],
                },
            ],
        }
    }

    getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({setSelectedKeys, selectedKeys, confirm, clearFilters}) => (
            <div style={{padding: 8}}>
                <Input
                    ref={(node) => {
                        this.searchInput = node
                    }}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{width: 188, marginBottom: 8, display: "block"}}
                />
                <Button type="primary" onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)} icon="search" size="small" style={{width: 90, marginRight: 8}}>
                    Search
                </Button>
                <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{width: 90}}>
                    Reset
                </Button>
            </div>
        ),
        filterIcon: (filtered) => <Icon type="search" style={{color: filtered ? "#1890ff" : undefined}} />,
        onFilter: (value, record) => record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
                setTimeout(() => this.searchInput.select())
            }
        },
        render: (text) => (this.state.searchedColumn === dataIndex ? <Highlighter highlightStyle={{backgroundColor: "#ffc069", padding: 0}} searchWords={[this.state.searchText]} autoEscape textToHighlight={text.toString()} /> : text),
    })

    handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm()
        this.setState({
            searchText: selectedKeys[0],
            searchedColumn: dataIndex,
        })
    }

    handleReset = (clearFilters) => {
        clearFilters()
        this.setState({searchText: ""})
    }

    delete = (data) => {
        if (window.confirm("Are you sure to delete?")) {
            axios({
                method: "post",
                url: apiUrl + "website.php",
                headers: {"content-type": "application/x-www-form-urlencoded"},
                data: qs.stringify({id: data.id, action: "delete"}),
            }).then((res) => {
                if (res && res.data) {
                    message.success("Success !!!", 1)
                    this.setState({dataSource: res.data.websites, customers: res.data.customers})
                } else {
                    message.error("Error !!!, See console log", 1)
                    console.log("res", res)
                }
            })
        }
    }

    modalEditQuatation = (data) => {
        this.setState({currentData: data, visibleQuatation: true})
    }

    modalEditInvoice = (data) => {
        this.setState({currentData: data, visibleInvoice: true})
    }

    modalEditReceive = (data) => {
        this.setState({currentData: data, visibleReceive: true})
    }

    modalEditWebsite = (data) => {
        this.setState({currentData: data, visibleWebsite: true})
    }

    modalAddWebsite = (data) => {
        this.setState({currentData: {}, visibleWebsite: true, action: "add"})
    }

    handleState = (data) => {
        this.setState(data)
    }

    refetch = () => {
        axios.get(apiUrl + "website.php").then((res) => {
            if (res.data) {
                this.setState({dataSource: res.data.websites, customers: res.data.customers})
            }
        })
    }

    componentDidMount = () => {
        this.refetch()
    }

    render() {
        const {dataSource, columns, visibleWebsite, visibleQuatation, visibleInvoice, visibleReceive, customers, currentData, action} = this.state
        return (
            <div id="container">
                <div id="top-bar">
                    <button className="btn-add" onClick={this.modalAddWebsite}>
                        <Icon type="plus" />
                        <span>New website</span>
                    </button>
                </div>
                <Table pagination={false} dataSource={dataSource} id="project-table" size="middle" bordered columns={columns} rowKey={(record) => record.id} />
                {visibleWebsite && <FormWebsite customers={customers} visibleWebsite={visibleWebsite} currentData={currentData} action={action} handleState={this.handleState} />}
                {visibleQuatation && <FormQuatation refetch={this.refetch} visibleQuatation={visibleQuatation} currentData={currentData} handleState={this.handleState} />}
                {visibleInvoice && <FormInvoice refetch={this.refetch} visibleInvoice={visibleInvoice} currentData={currentData} handleState={this.handleState} />}
                {visibleReceive && <FormReceive refetch={this.refetch} visibleReceive={visibleReceive} currentData={currentData} handleState={this.handleState} />}
            </div>
        )
    }
}

export default Website
