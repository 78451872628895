import React from "react"
import PropTypes from "prop-types"
import axios from "axios"
import qs from "qs"
import numeral from "numeral"
import moment from "moment"
import "moment/locale/th.js"
import {Form, Modal, message, DatePicker, Switch} from "antd"
import {apiUrl, profile} from "../../consts"
import {convertNumberToWords, getReceiveNumber} from "../../modules"
const FormItem = Form.Item

class FormReceive extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            invoiceNumber: ""
        }
    }
    handleCancel = (e) => {
        this.props.handleState({visibleReceive: false})
    }
    handleSubmit = (e) => {
        e.preventDefault()
        const {currentData, handleState, refetch} = this.props
        const {quatation} = currentData
        this.props.form.validateFields((err, values) => {
            if (!err) {
                const data = {
                    action: "addReceive",
                    id: currentData.invoice && currentData.invoice.id ? currentData.invoice.id : false,
                    quatations_id: quatation.id,
                    phase: values.phase,
                    receive_not_sure_date: values.receive_not_sure_date ? '1' : '0',
                    date: moment(values.date).format("YYYY-MM-DD")
                }
                console.log('data', data)
                axios({
                    method: "post",
                    url: apiUrl + "invoice.php",
                    headers: {"content-type": "application/x-www-form-urlencoded"},
                    data: qs.stringify(data)
                }).then((res) => {
                    if (res && res.data) {
                        message.success("Success !!!", 1)
                        handleState({visibleReceive: false})
                        refetch()
                    } else {
                        message.error("Error !!!, See console log", 1)
                        console.log("res", res)
                    }
                })
            }
        })
    }

    delete = () => {
        if (window.confirm("Are you sure to delete?")) {
            const {currentData, handleState, refetch} = this.props
            axios({
                method: "post",
                url: apiUrl + "invoice.php",
                headers: {"content-type": "application/x-www-form-urlencoded"},
                data: qs.stringify({action: "delete", id: currentData.invoice.id})
            }).then((res) => {
                if (res && res.data) {
                    message.success("Success !!!", 1)
                    handleState({visibleReceive: false})
                    refetch()
                } else {
                    message.error("Error !!!, See console log", 1)
                    console.log("res", res)
                }
            })
        }
    }

    componentDidMount = () => {
        const {currentData} = this.props
        if (currentData.invoice && currentData.invoice.invoice_number) {
            this.setState({invoiceNumber: currentData.invoice.invoice_number})
        } else if (currentData) {
            axios({
                method: "post",
                url: apiUrl + "invoice.php",
                headers: {"content-type": "application/x-www-form-urlencoded"},
                data: qs.stringify({action: "invoice_number"})
            }).then((res) => {
                if (res && (res.data || res.data === 0)) {
                    console.log("invoice_number", res.data)
                    this.setState({invoiceNumber: parseInt(res.data) + 1})
                } else {
                    message.error("Error !!!, See console log", 1)
                    console.log("res", res)
                }
            })
        }
    }

    render() {
        const {invoiceNumber} = this.state
        const {currentData, visibleReceive, form} = this.props
        console.log("currentData", currentData)

        const {website, quatation, invoice} = currentData
        const {getFieldDecorator} = form
        const date = form.getFieldValue("date") ? form.getFieldValue("date") : invoice.receive_date ? invoice.receive_date : new Date()

        let phase = form.getFieldValue("phase") ? form.getFieldValue("phase") : invoice.phase ? invoice.phase : false
        if (!phase && invoice && invoice.id) return message.error("Phase invalid")
        phase = parseInt(phase)

        return (
            <Modal width={900} visible={visibleReceive} onOk={this.handleSubmit} onCancel={this.handleCancel} className="quatationModal">
                <Form>
                    <div id="quatation" className="print invoice">
                        <div id="head">
                            <div className="left">
                                <img src="/img/logo.png" alt="logo" />
                            </div>
                            <div className="right">
                                <h1>ใบเสร็จรับเงิน</h1>
                                <div className="col2">
                                    <p>เลขที่ : </p>
                                    <p className="borderBottom">{getReceiveNumber(date, invoiceNumber)}</p>
                                </div>
                                <div className="col2">
                                    <p>วันที่ : </p>
                                    <FormItem>
                                        {getFieldDecorator("date", {
                                            rules: [{required: true, message: "Please enter date"}],
                                            initialValue: moment(date, "YYYY-MM-DD")
                                        })(<DatePicker />)}
                                    </FormItem>
                                </div>
                                <div className="switch-date">
                                    <FormItem>
                                        {getFieldDecorator("receive_not_sure_date", {
                                            rules: [{required: false}]
                                        })(<Switch defaultChecked={invoice.receive_not_sure_date === "1" ? true : false} checkedChildren={<p className="switch-p">ไม่แน่ใจวันที่</p>} unCheckedChildren={<p className="switch-p">วันที่ถูกต้องแล้ว</p>} />)}
                                    </FormItem>
                                </div>
                            </div>
                        </div>
                        {date && (
                            <p className="date-thai">
                                <span>{typeof date === "object" ? moment(date).format("D MMMM YYYY") : date}</span>
                            </p>
                        )}
                        <div id="head2" className="col2">
                            <div>
                                <div className="col2">
                                    <p>ผู้รับจ้าง</p>
                                    <div>
                                        <p>{profile.name}</p>
                                    </div>
                                </div>
                                <div className="col2">
                                    <p>ที่อยู่</p>
                                    <div>
                                        <p>{profile.address}</p>
                                    </div>
                                </div>
                                <div className="col2">
                                    <p>เลขประจำตัวผู้เสียภาษีอากร</p>
                                    <div>
                                        <p>{profile.id}</p>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div className="col2">
                                    <p>ผู้ว่าจ้าง</p>
                                    {website.address && (
                                        <div>
                                            <p>{website.address.name}</p>
                                        </div>
                                    )}
                                </div>
                                <div className="col2">
                                    <p>ที่อยู่</p>
                                    {website.address && (
                                        <div>
                                            <p>{website.address.address}</p>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div id="table" className="relative">
                            <table>
                                <thead>
                                    <tr>
                                        <th>บริการ</th>
                                        <th>หน่วย</th>
                                        <th>ราคาต่อหน่วย</th>
                                        <th>จำนวนเงิน(บาท)</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr className="title">
                                        <td colSpan="4">
                                            <p id="title" dangerouslySetInnerHTML={{__html: quatation.name}} />
                                        </td>
                                    </tr>
                                    {quatation.topic.map((value, key) => {
                                        return (
                                            <tr key={key}>
                                                <td>
                                                    <p className="topic" dangerouslySetInnerHTML={{__html: quatation.estimate_time[key] && quatation.estimate_time[key] !== "" ? quatation.topic[key] + "<span>(" + quatation.estimate_time[key] + ")</span>" : value}} />
                                                    <div className="textEditor" dangerouslySetInnerHTML={{__html: quatation.description[key]}} />
                                                </td>
                                                <td>{numeral(quatation.quantity[key] ? quatation.quantity[key] : 0).format("0,0")}</td>
                                                <td>{numeral(quatation.price[key] ? quatation.price[key] : 0).format("0,0")}</td>
                                                <td>{numeral(quatation.total[key] ? quatation.total[key] : 0).format("0,0")}</td>
                                            </tr>
                                        )
                                    })}
                                    <tr>
                                        <td>&nbsp;</td>
                                        <td colSpan="3" className={phase > 0 ? "bgff" : ""}>
                                            {phase === -1 && (
                                                <div>
                                                    <p>จำนวนเงินทั้งสิ้น</p>
                                                    <div>
                                                        <b>{numeral(quatation.net || 0).format("0,0")}</b>
                                                        {quatation.net && <p>({convertNumberToWords(quatation.net || 0)})</p>}
                                                    </div>
                                                </div>
                                            )}
                                            {phase > 0 && (
                                                <div className="phase">
                                                    <div>
                                                        <p>รวม</p>
                                                        <p>{numeral(quatation.net || 0).format("0,0")}</p>
                                                    </div>
                                                    <div>
                                                        <p>งวดที่ {phase} ชำระ 50%</p>
                                                        <p>-50%</p>
                                                    </div>
                                                    <div>
                                                        <p>จำนวนเงินทั้งสิ้น</p>
                                                        <div>
                                                            <b>{numeral(quatation.net / 2).format("0,0")}</b>
                                                            {quatation.net && <p>({convertNumberToWords(quatation.net / 2)})</p>}
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div id="signature">
                            <table>
                                <tbody>
                                    <tr>
                                        <td>
                                            <p>ลงชื่อ</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <p>{profile.prefix + profile.name}</p>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        {invoice &&
                            invoice.id && (
                                <div id="deleteQuatation">
                                    <button type="button" className="btn-delete" onClick={this.delete}>
                                        Delete
                                    </button>
                                </div>
                            )}
                    </div>
                </Form>
            </Modal>
        )
    }
}

FormReceive.propTypes = {
    currentData: PropTypes.object.isRequired,
    handleState: PropTypes.func.isRequired,
    visibleReceive: PropTypes.bool.isRequired,
    refetch: PropTypes.func.isRequired
}

const WrappedFormReceive = Form.create()(FormReceive)
export default WrappedFormReceive
