import moment from "moment"

export const convertNumberToWords = (num) => {
    let returnNumWord = ""
    num = num.toString().replace(",", "")
    const num_decimal = num.split(".")
    num = num_decimal[0]
    const lenNumber = num.length
    const lenNumber2 = lenNumber - 1

    const kaGroup = ["", "สิบ", "ร้อย", "พัน", "หมื่น", "แสน", "ล้าน", "สิบ", "ร้อย", "พัน", "หมื่น", "แสน", "ล้าน"]
    const kaDigit = ["", "หนึ่ง", "สอง", "สาม", "สี่", "ห้า", "หก", "เจ็ด", "แปด", "เก้า"]
    const kaDigitDecimal = ["ศูนย์", "หนึ่ง", "สอง", "สาม", "สี่", "ห้า", "หก", "เจ็ด", "แปด", "เก้า"]

    let ii = 0
    let i
    let kaNumWord = []
    for (i = lenNumber2; i >= 0; i--) {
        kaNumWord[i] = parseInt(num.substr(ii, 1))
        ii++
    }

    ii = 0
    i = lenNumber2
    for (i = lenNumber2; i >= 0; i--) {
        if ((kaNumWord[i] === 2 && i === 1) || (kaNumWord[i] === 2 && i === 7)) {
            kaDigit[kaNumWord[i]] = "ยี่"
        } else {
            if (kaNumWord[i] === 2) {
                kaDigit[kaNumWord[i]] = "สอง"
            }
            if ((kaNumWord[i] === 1 && i <= 2 && i === 0) || (kaNumWord[i] === 1 && lenNumber > 6 && i === 6)) {
                if (kaNumWord[i + 1] === 0) {
                    kaDigit[kaNumWord[i]] = "หนึ่ง"
                } else {
                    kaDigit[kaNumWord[i]] = "เอ็ด"
                }
            } else if ((kaNumWord[i] === 1 && i <= 2 && i === 1) || (kaNumWord[i] === 1 && lenNumber > 6 && i === 7)) {
                kaDigit[kaNumWord[i]] = ""
            } else {
                if (kaNumWord[i] === 1) {
                    kaDigit[kaNumWord[i]] = "หนึ่ง"
                }
            }
        }
        if (kaNumWord[i] === 0) {
            if (i !== 6) {
                kaGroup[i] = ""
            }
        }
        kaNumWord[i] = num.substr(ii, 1)
        ii++
        returnNumWord += kaDigit[kaNumWord[i]] + "" + kaGroup[i]
    }
    if (num_decimal[1]) {
        returnNumWord += "จุด"
        const decimalLength = num_decimal[1].length
        for (i = 0; i < decimalLength; i++) {
            returnNumWord += kaDigitDecimal[num_decimal[1].substr(i, 1)]
        }
    }
    return returnNumWord + "บาทถ้วน"
}

export const getQuatationNumber = (date, number) => {
    const pad = (n) => {
        return n < 10 ? "0" + n : n
    }
    let quatationNumber = (parseInt(moment(date).format("YY")) + 43).toString()
    quatationNumber += moment(date).format("MMDD") + "-" + pad(number)
    return "QT-" + quatationNumber
}

export const getInvoiceNumber = (date, number) => {
    const pad = (n) => {
        return n < 10 ? "0" + n : n
    }
    let invoiceNumber = (parseInt(moment(date).format("YY")) + 43).toString()
    invoiceNumber += moment(date).format("MMDD") + "-" + pad(number)
    return "IV-" + invoiceNumber
}

export const getReceiveNumber = (date, number) => {
    const pad = (n) => {
        return n < 10 ? "0" + n : n
    }
    let invoiceNumber = (parseInt(moment(date).format("YY")) + 43).toString()
    invoiceNumber += moment(date).format("MMDD") + "-" + pad(number)
    return "RC-" + invoiceNumber
}

export const getHostingNumber = (date, number) => {
    const pad = (n) => {
        return n < 10 ? "0" + n : n
    }
    let invoiceNumber = (parseInt(moment(date).format("YY")) + 43).toString()
    invoiceNumber += moment(date).format("MMDD") + "-" + pad(number)
    return "HT-" + invoiceNumber
}
