import React from "react"
import {Route, Link} from "react-router-dom"
import {Icon} from "antd"
import $ from "jquery"

export const PublicRoute = ({component: Component, ...rest}) => (
    <Route
        {...rest}
        render={(props) => {
            const path = props.location.pathname
            const reg = /customer\/*/

            const toggleAside = () => {
                $('main>aside').toggleClass('hide')
                $('main').toggleClass('hide')
            }

            return (
                <React.Fragment>
                    <header />
                    <main  className="hide">
                        <aside className="hide">
                            <p>
                                MaoWeb
                                <span>Backend systems</span>
                            </p>
                            <nav>
                                <ul>
                                    <li className={path === "/website" || path === "/" ? "active" : ""}>
                                        <Link to="/">
                                            <Icon type="global" theme="outlined" />
                                            <span>Website</span>
                                        </Link>
                                    </li>
                                    <li className={reg.test(path) === true ? "active" : ""}>
                                        <Link to="/customers">
                                            <Icon type="team" theme="outlined" />
                                            <span>Customer</span>
                                        </Link>
                                    </li>
                                    <li>
                                        <button
                                            type="button"
                                            onClick={() => {
                                                window.localStorage.removeItem("token")
                                                window.location.reload()
                                            }}
                                        >
                                            <Icon type="logout" theme="outlined" />
                                            <span>Logout</span>
                                        </button>
                                    </li>
                                </ul>
                            </nav>
                            <button type="button" id="btn-aside" onClick={() => toggleAside()}>
                                <Icon type="right" theme="outlined" />
                            </button>
                        </aside>
                        <Component {...props} {...rest} />
                    </main>
                    <footer />
                </React.Fragment>
            )
        }}
    />
)

export const BlankPageRoute = ({component: Component, ...rest}) => (
    <Route
        {...rest}
        render={(props) => (
            <React.Fragment>
                <Component {...props} {...rest} />
            </React.Fragment>
        )}
    />
)
