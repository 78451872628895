import React from "react"
import {Link} from "react-router-dom"
import axios from "axios"
import qs from "qs"
import {Table, Icon, message} from "antd"
import {apiUrl} from "../../consts"
import FormCustomer from "./FormCustomer"

class Customer extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            visibleCustomer: false,
            dataSource: [],
            currentData: {},
            columns: [
                {
                    title: "Name",
                    dataIndex: "name",
                    key: "name"
                },
                {
                    title: "Email",
                    dataIndex: "email",
                    key: "email"
                },
                {
                    title: "Phone",
                    dataIndex: "phone",
                    key: "phone"
                },
                {
                    title: "Address",
                    dataIndex: "address",
                    key: "address",
                    render: (text, record) => {
                        return (
                            <Link to={"/customer/" + record.id} className="btn-default">
                                <span className="dimb mr5">Address</span>
                                <span className="dimb">({text.length})</span>
                            </Link>
                        )
                    }
                },
                {
                    title: "Action",
                    dataIndex: "id",
                    key: "action",
                    render: (text, record) => {
                        return <Icon type="form" onClick={(e) => this.modalEditCustomer(record)} />
                    }
                }
            ]
        }
    }

    refetch = () => {
        axios({
            method: "post",
            url: apiUrl + "customer.php",
            headers: {"content-type": "application/x-www-form-urlencoded"},
            data: qs.stringify({action: "getCustomers"})
        }).then((res) => {
            if (res && res.data) {
                this.setState({dataSource: res.data})
            } else {
                message.error("Error !!!, See console log", 1)
                console.log("res", res)
            }
        })
    }

    modalEditCustomer = (data = {}) => {
        this.setState({visibleCustomer: true, currentData: data})
    }

    handleState = (handleState) => {
        this.setState(handleState)
    }

    componentDidMount = () => {
        this.refetch()
    }

    render() {
        const {dataSource, visibleCustomer, currentData, columns} = this.state

        return (
            <div id="container">
                <div id="top-bar">
                    <button className="btn-add" onClick={this.modalEditCustomer}>
                        <Icon type="plus" />
                        <span>New customer</span>
                    </button>
                </div>
                <Table pagination={false} dataSource={dataSource} id="customer-table" size="middle" bordered columns={columns} rowKey={(record) => record.key} />
                {visibleCustomer && <FormCustomer visibleCustomer={visibleCustomer} refetch={this.refetch} currentData={currentData} handleState={this.handleState} />}
            </div>
        )
    }
}

export default Customer
