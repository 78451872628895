import React from "react"
import {Form, Icon, Input, Button} from "antd"
import {post} from "../../consts"

import "./login.css"

class LoginPage extends React.Component {
    handleSubmit = (e) => {
        e.preventDefault()
        const {form} = this.props
        form.validateFields((err, values) => {
            if (!err) {
                post("login.php", values, (res) => {
                    if (res.data && res.data.token) {
                        window.localStorage.setItem("token", res.data.token)
                        window.location.reload()
                    }
                })
            }
        })
    }
    render() {
        const {form} = this.props
        const {getFieldDecorator} = form
        return (
            <Form onSubmit={this.handleSubmit} className="login-form">
                <Form.Item>
                    {getFieldDecorator("username", {
                        rules: [{required: true, message: "Please input your username!"}]
                    })(<Input prefix={<Icon type="user" />} placeholder="Username" />)}
                </Form.Item>
                <Form.Item>
                    {getFieldDecorator("password", {
                        rules: [{required: true, message: "Please input your Password!"}]
                    })(<Input prefix={<Icon type="lock" />} type="password" placeholder="Password" />)}
                </Form.Item>
                <Form.Item>
                    <Button type="primary" htmlType="submit" className="login-form-button">
                        Log in
                    </Button>
                </Form.Item>
            </Form>
        )
    }
}

const WrappedLoginPage = Form.create({name: "normal_login"})(LoginPage)

export default WrappedLoginPage
