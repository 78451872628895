import axios from "axios"
import qs from "qs"

export const status = {
    requirement: "More req.",
    open: "Open",
    burning: "Burning",
    feedback: "Feedback",
    close: "Close",
    failed: "Failed",
    canceled: "Canceled",
    wait: "Waiting",
    service: "Service",
    wait_feedback: "Verify"
}

// export const apiUrl = window.location.hostname === "localhost" ? "http://localhost:9000/" : "/server/"
export const apiUrl = "https://maoweb.net/server/"

// export const termOfPayments = ["โปรดชำระเงินก่อนเริ่มงาน", "งวดที่ 1 ชำระ 50% ก่อนเริ่มงาน<br/>งวดที่ 2 ชำระ 50% หลังส่งมอบงาน", "ชำระเงินทั้งหมดก่อนส่งมอบงาน"]
export const termOfPayments = [{label: "โปรดชำระเงินก่อนเริ่มงาน", value: "0", phase: "1"}, {label: "--งวดที่ 1 ชำระ 50% ก่อนเริ่มงาน \n --งวดที่ 2 ชำระ 50% หลังส่งมอบงาน", value: "1", phase: "2"}, {label: "ชำระเงินทั้งหมดก่อนส่งมอบงาน", value: "2", phase: "1"}]
export const quatationNote = [
    {label: "ยืนยันราคา 30 วันนับจากวันเสนอราคา", value: "0"},
    {label: "ดูแลหลังจากส่งมอบงาน เป็นเวลา 6 เดือน", value: "1"},
    {label: "ดูแลหลังจากส่งมอบงาน เป็นเวลา 1 ปี", value: "2"},
    {label: "ส่งใบเรียกเก็บเงินงวดที่ 1 พร้อมเอกสารชี้แจงระยะเวลาในการพัฒนา ภายหลังการอนุมัติใบเสนอราคา", value: "3"}
]

export const profile = {
    id: "1502000044607",
    prefix: "นาย",
    name: "นภสินธุ์ เหล็กเทศ",
    address: "205 หมู่ 5 ต.แม่ฮี้ อ.ปาย จ.แม่ฮ่องสอน 58130",
    banks: [{id: "892-227643-4", name: "ไทยพาณิชย์", branch: "เทสโก้ โลตัส เชียงใหม่ - กาดคำเที่ยง", logo: "logo_scb.png"}, {id: "587-2-25696-4", name: "กสิกรไทย", branch: "เทสโก้ โลตัส เชียงใหม่ - กาดคำเที่ยง", logo: "k_bank.png"}]
}

export const post = (uri, data, callback) => {
    axios({
        method: "post",
        url: apiUrl + uri,
        data: qs.stringify(data),
        headers: {
            "content-type": "application/x-www-form-urlencoded"
        }
    }).then((res) => {
        callback(res)
    })
}

export const get = (uri, data, callback) => {
    axios
        .get(apiUrl + uri, {
            params: data
        })
        .then((res) => {
            callback(res)
        })
}
