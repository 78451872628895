import React from "react"
import axios from "axios"
import moment from "moment"
import numeral from "numeral"
import qs from "qs"
import $ from "jquery"
import {Helmet} from "react-helmet"
import {message, Switch, Button} from "antd"
import queryString from "query-string"

import {convertNumberToWords, getReceiveNumber, getInvoiceNumber} from "../../modules"
import {profile, apiUrl} from "../../consts"

class Receive extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            quatation: {},
            website: {},
            signatureStatus: false,
        }
    }

    pad = (n) => {
        return n < 10 ? "0" + n : n
    }

    handleChange = (status) => {
        this.setState({signatureStatus: status})
    }

    componentDidMount = () => {
        const {match} = this.props
        const id = match.params && match.params.id ? match.params.id : false
        const qid = match.params && match.params.qid ? match.params.qid : false
        if (id && qid) {
            axios({
                method: "post",
                url: apiUrl + "invoice.php",
                headers: {"content-type": "application/x-www-form-urlencoded"},
                data: qs.stringify({action: "dataForPrint", id: id, qid: qid}),
            }).then((res) => {
                if (res && res.data) {
                    this.setState({quatation: res.data.quatation, website: res.data.website, invoice: res.data.invoice})
                } else {
                    message.error("Error !!!, See console log", 1)
                    console.log("res", res)
                }
            })
        }
        $("body").css({background: "#191919"})
    }

    render() {
        const {website, quatation, signatureStatus, invoice} = this.state
        const {location} = this.props
        if (!website.id || !quatation.id || !invoice.id) return false
        const date = invoice.receive_date.split("-")
        const search = queryString.parse(location.search)
        const receiveNumber = getReceiveNumber(invoice.receive_date, invoice.invoice_number)
        const invoiceNumber = getInvoiceNumber(invoice.date, invoice.invoice_number)

        let showLogo = false
        let lang = "th"
        if (typeof search === "object") {
            showLogo = typeof search.logo !== "undefined" ? true : false
            if (typeof search.lang !== "undefined") {
                lang = search.lang === "en" ? "en" : "th"
            }
            if (typeof search.en !== "undefined") {
                lang = "en"
            }
        }
        let topic = quatation.name
        if (invoice.phase !== "-1") {
            topic = quatation.name + " (งวดที่ " + invoice.phase + ")"
        }

        const hasPhase = invoice.phase !== "-1"

        return (
            <div id="quatation" className="print invoiceprint receive">
                <Helmet>
                    <title>{receiveNumber + "@" + website.website.toLowerCase()}</title>
                </Helmet>
                <div id="head" className={showLogo ? "logo" : ""}>
                    <div className="left">
                        {showLogo && <img src="/img/logo_trim.png" alt="logo" />}
                        <div>
                            <p>นาย{profile.name}</p>
                        </div>
                        <div>
                            <p>{profile.address}</p>
                        </div>
                        <div>
                            <p>โทร. 084 2964452 เลขประจำตัวผู้เสียภาษี {profile.id}</p>
                        </div>
                    </div>
                    <div className="right">
                        <h1>ใบเสร็จรับเงิน</h1>
                        <div className="col2">
                            <p>เลขที่ : </p>
                            <p>{receiveNumber}</p>
                        </div>
                        <div className="col2">
                            <p>วันที่ : </p>
                            <p>
                                {moment()
                                    .date(date[2])
                                    .month(date[1] - 1)
                                    .year(parseInt(date[0]) + 543)
                                    .format("D MMMM YYYY")}
                            </p>
                        </div>
                        <div className="col2">
                            <p>อ้างอิงใบแจ้งหนี้เลขที่ : {invoiceNumber}</p>
                        </div>
                    </div>
                </div>
                <div id="head2">
                    <div>
                        <p className="mb5">
                            <b>ผู้ว่าจ้าง</b>
                        </p>
                        {website.address.name && <p className="mb0">ชื่อผู้ติดต่อ {website.address.name}</p>}
                        {website.address.business && <p className="mb0">บริษัท {website.address.business}</p>}
                        <p className="mb0">
                            <span dangerouslySetInnerHTML={{__html: website.address.address}} />
                        </p>
                    </div>
                </div>
                <div id="table">
                    <table>
                        <thead>
                            <tr>
                                <th>บริการ</th>
                                <th>หน่วย</th>
                                <th>ราคาต่อหน่วย</th>
                                <th>{lang === "en" ? "Total" : "ยอดรวม"}</th>
                                {hasPhase && <th>{lang === "en" ? "Amount" : "ยอดชำระ"}</th>}
                            </tr>
                        </thead>
                        <tbody>
                            <tr className="title">
                                <td colSpan={hasPhase ? 5 : 4}>
                                    <p id="title" dangerouslySetInnerHTML={{__html: topic}} />
                                </td>
                            </tr>
                            {quatation.detail.topic.map((value, key) => {
                                return (
                                    <tr key={key}>
                                        <td>
                                            <p className="topic" dangerouslySetInnerHTML={{__html: quatation.detail.estimate_time[key] && quatation.detail.estimate_time[key] !== "" ? value + "<span>(" + quatation.detail.estimate_time[key] + ")</span>" : value}} />
                                            <div className="textEditor" dangerouslySetInnerHTML={{__html: quatation.detail.description[key]}} />
                                        </td>
                                        <td>{numeral(quatation.detail.quantity[key] ? quatation.detail.quantity[key] : 0).format("0,0")}</td>
                                        <td>{numeral(quatation.detail.price[key] ? quatation.detail.price[key] : 0).format("0,0")}</td>
                                        <td>{numeral(quatation.detail.total[key] ? quatation.detail.total[key] : 0).format("0,0")}</td>
                                        {hasPhase && <td>{numeral(quatation.detail.total[key] ? quatation.detail.total[key] / 2 : 0).format("0,0")}</td>}
                                    </tr>
                                )
                            })}
                            <tr>
                                <td>&nbsp;</td>
                                <td colSpan={hasPhase ? 4 : 3} className={hasPhase ? "bgff" : ""}>
                                    {invoice.phase === "-1" && (
                                        <div>
                                            <p>{lang === "en" ? "Total" : "จำนวนเงินทั้งสิ้น"}</p>
                                            <div>
                                                <b>
                                                    {numeral(quatation.net || 0).format("0,0")}
                                                    {lang === "en" ? " THB" : ""}
                                                </b>
                                                {lang === "en" ? "" : quatation.net && <p>({convertNumberToWords(quatation.net || 0)})</p>}
                                            </div>
                                        </div>
                                    )}
                                    {hasPhase && (
                                        <div className="phase">
                                            <div>
                                                <p>{lang === "en" ? "Total" : "จำนวนเงินทั้งสิ้น"}</p>
                                                <div>
                                                    <b>{numeral(quatation.net / 2).format("0,0")}</b>
                                                    {quatation.net && <p>({convertNumberToWords(quatation.net / 2)})</p>}
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div id="signature">
                    <table>
                        <tbody>
                            <tr>
                                <td>
                                    <p>ลงชื่อ</p>
                                    {signatureStatus && <img src="/img/signature.png" alt="" />}
                                    <Switch onChange={this.handleChange} checkedChildren={<p className="switch-p">Show signature</p>} unCheckedChildren={<p className="switch-p">Hidden signature</p>}  />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <p>({profile.prefix + profile.name})</p>
                                    <p>
                                        {moment()
                                            .date(date[2])
                                            .month(date[1] - 1)
                                            .year(parseInt(date[0]) + 543)
                                            .format("D MMMM YYYY")}
                                    </p>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className="text-center mt30 hidden-print">
                    <Button icon="file-pdf" onClick={window.print} className="btn-print">
                        Print
                    </Button>
                </div>
            </div>
        )
    }
}

export default Receive
