import React from "react"
import {Switch} from "react-router-dom"
import {PublicRoute, BlankPageRoute} from "./layouts"
import {get} from "./consts.js"

import Website from "./pages/website"
import Customer from "./pages/customer"
import Quatation from "./pages/quatation"
import Invoice from "./pages/invoice"
import Receive from "./pages/receive"
import Service from "./pages/receive/service"
import Hosting from "./pages/hosting"
import Login from "./pages/login"
import CustomerAddress from "./pages/customer/address"

class AppRouter extends React.Component {
    state = {loged: false, loading: true}
    componentDidMount = () => {
        this.setState({loading: true})
        if (window.localStorage.getItem("token")) {
            get("login.php", {token: window.localStorage.getItem("token")}, (res) => {
                if (res.data && res.data.token) {
                    if (res.data.token === window.localStorage.getItem("token")) {
                        this.setState({loged: true, loading: false})
                    }
                } else {
                    window.localStorage.clear()
                }
            })
        }
    }
    render() {
        const {loged, loading} = this.state
        if (loading && window.localStorage.getItem("token")) return null
        const privateRounter = (
            <Switch>
                <PublicRoute path="/customers" component={Customer} />
                <PublicRoute path="/customer/:id" component={CustomerAddress} />
                <BlankPageRoute path="/quatation/:id" component={Quatation} />
                <BlankPageRoute path="/invoice/:qid/:id" component={Invoice} />
                <BlankPageRoute path="/receive/:qid/:id" component={Receive} />
                <BlankPageRoute path="/service/:qid/:id" component={Service} />
                <BlankPageRoute path="/hosting/:id" component={Hosting} />
                <PublicRoute path="/" component={Website} />
            </Switch>
        )

        const publicRouting = (
            <Switch>
                <BlankPageRoute path="/" component={Login} />
            </Switch>
        )

        return loged ? privateRounter : publicRouting
    }
}

export default AppRouter
