import React from "react"
import PropTypes from "prop-types"
import axios from "axios"
import qs from "qs"
import "moment/locale/th.js"
import {Form, Modal, message, Input} from "antd"
import {apiUrl} from "../../consts"
const FormItem = Form.Item

class FormCustomer extends React.Component {
    handleCancel = (e) => {
        this.props.handleState({visibleCustomer: false})
    }
    handleSubmit = (e) => {
        e.preventDefault()
        const {handleState, refetch, currentData} = this.props
        this.props.form.validateFields((err, values) => {
            if (!err) {
                axios({
                    method: "post",
                    url: apiUrl + "customer.php",
                    headers: {"content-type": "application/x-www-form-urlencoded"},
                    data: qs.stringify({action: "manage", ...values, id: currentData.id || false})
                }).then((res) => {
                    if (res && res.data) {
                        message.success("Success !!!", 1)
                        handleState({visibleCustomer: false})
                        refetch()
                    } else {
                        message.error("Error !!!, See console log", 1)
                        console.log("res", res)
                    }
                })
            }
        })
    }

    render() {
        const {form, visibleCustomer, currentData} = this.props
        const {getFieldDecorator} = form
        return (
            <Modal width={600} visible={visibleCustomer} onOk={this.handleSubmit} onCancel={this.handleCancel} className="quatationModal">
                <Form>
                    <FormItem label="Customer name">
                        {getFieldDecorator("name", {
                            rules: [{required: true, message: "Please enter name"}],
                            initialValue: currentData.name || ""
                        })(<Input placeholder="Customer name" />)}
                    </FormItem>
                    <FormItem label="Email">
                        {getFieldDecorator("email", {
                            rules: [{required: false}],
                            initialValue: currentData.email || ""
                        })(<Input placeholder="Email address" />)}
                    </FormItem>
                    <FormItem label="Phone">
                        {getFieldDecorator("phone", {
                            rules: [{required: false}],
                            initialValue: currentData.phone || ""
                        })(<Input placeholder="Phone number" />)}
                    </FormItem>
                </Form>
            </Modal>
        )
    }
}

FormCustomer.propTypes = {
    handleState: PropTypes.func.isRequired,
    visibleCustomer: PropTypes.bool.isRequired,
    refetch: PropTypes.func.isRequired,
    currentData: PropTypes.object
}

const WrappedFormCustomer = Form.create()(FormCustomer)
export default WrappedFormCustomer
