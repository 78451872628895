import React from "react"
import ReactDOM from "react-dom"
import {BrowserRouter} from "react-router-dom"
import createHistory from "history/createBrowserHistory"

import AppRouter from "./Router"

import "antd/dist/antd.min.css"
import "./assets/css/app.css"

const render = (Component) => {
    return ReactDOM.render(
        <BrowserRouter history={createHistory()}>
            <Component />
        </BrowserRouter>,
        document.getElementById("app")
    )
}

render(AppRouter)
