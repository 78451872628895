import React from "react"
import PropTypes from "prop-types"
import axios from "axios"
import qs from "qs"
import numeral from "numeral"
import moment from "moment"
import "moment/locale/th.js"
import {Form, Modal, Input, message, Radio, DatePicker, Checkbox, InputNumber} from "antd"
import {apiUrl, termOfPayments, profile, quatationNote} from "../../consts"
import {convertNumberToWords} from "../../modules"
const FormItem = Form.Item
const TextArea = Input.TextArea
const RadioGroup = Radio.Group
const CheckboxGroup = Checkbox.Group

class FormQuatation extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            website: {},
            tableRow: false,
            tableRowDefault: 3
        }
    }
    handleCancel = (e) => {
        this.props.handleState({visibleQuatation: false})
    }
    handleBlur = (e) => {
        this.setState({tableRow: parseInt(e.target.value)})
    }
    handleSubmit = (e) => {
        e.preventDefault()
        const {currentData, refetch, handleState} = this.props
        this.props.form.validateFields((err, values) => {
            if (!err) {
                const data = {
                    name: values.name,
                    note: values.note.join("~"),
                    date: moment(values.date).format("YYYY-MM-DD"),
                    quatation_number: values.quatation_number,
                    term_of_payment: values.term_of_payment[0],
                    topic: values.topic.join("~"),
                    description: values.description.join("~"),
                    quantity: values.quantity.join("~"),
                    price: values.price.join("~"),
                    total: values.total.join("~"),
                    websites_id: currentData.websites_id ? currentData.websites_id : currentData.id,
                    id: currentData.id,
                    estimate_time: values.estimate_time.join("~"),
                    action: currentData.websites_id ? "edit" : "add",
                    net: values.total.reduce(this.getSum)
                }
                console.log("data", data)
                axios({
                    method: "post",
                    url: apiUrl + "quatation.php",
                    headers: {"content-type": "application/x-www-form-urlencoded"},
                    data: qs.stringify(data)
                }).then((res) => {
                    if (res && res.data) {
                        message.success("Success !!!", 1)
                        handleState({visibleQuatation: false})
                        refetch()
                    } else {
                        message.error("Error !!!, See console log", 1)
                        console.log("res", res)
                    }
                })
            }
        })
    }

    getSum = (total, num) => {
        return parseFloat(total) + parseFloat(num)
    }

    setTotalPrice = (e, key) => {
        let total = this.props.form.getFieldValue("total")
        let quantity = this.props.form.getFieldValue("quantity")
        total[key] = parseFloat(e.target.value || 0) * parseInt(quantity[key])
        this.props.form.setFieldsValue({total: total})
    }

    delete = () => {
        if (window.confirm("Are you sure to delete?")) {
            const {currentData, handleState, refetch} = this.props
            axios({
                method: "post",
                url: apiUrl + "quatation.php",
                headers: {"content-type": "application/x-www-form-urlencoded"},
                data: qs.stringify({action: "delete", id: currentData.id})
            }).then((res) => {
                if (res && res.data) {
                    message.success("Success !!!", 1)
                    handleState({visibleQuatation: false})
                    refetch()
                } else {
                    message.error("Error !!!, See console log", 1)
                    console.log("res", res)
                }
            })
        }
    }

    componentDidMount = () => {
        const {currentData} = this.props
        const id = currentData.websites_id ? currentData.websites_id : currentData.id ? currentData.id : false
        if (currentData && id) {
            axios({
                method: "post",
                url: apiUrl + "quatation.php",
                headers: {"content-type": "application/x-www-form-urlencoded"},
                data: qs.stringify({action: "getWebsite", websites_id: id})
            }).then((res) => {
                if (res && res.data) {
                    this.setState({website: res.data})
                } else {
                    message.error("Error !!!, See console log", 1)
                    console.log("res", res)
                }
            })
        }
    }

    render() {
        const {currentData, visibleQuatation} = this.props

        const {website, tableRow, tableRowDefault} = this.state
        const {getFieldDecorator} = this.props.form

        const topic = currentData.topic || []
        const description = currentData.description || []
        const quantity = currentData.quantity || []
        const price = currentData.price || []
        const total = currentData.total || []
        const estimate_time = currentData.estimate_time || []

        const currentTableRow = tableRow === false && topic.length > 0 ? Array.apply(null, {length: topic.length}) : Array.apply(null, {length: tableRow ? tableRow : tableRowDefault})

        let net = this.props.form.getFieldValue("total") ? this.props.form.getFieldValue("total") : []
        if (net && net[0]) {
            net = net.map((value, key) => {
                if (value && value !== "") {
                    return parseFloat(value)
                }
                return 0
            })
            net = net.reduce(this.getSum)
        } else {
            net = 0
        }

        const date = this.props.form.getFieldValue("date") ? this.props.form.getFieldValue("date") : currentData.date ? currentData.date : false
        console.log("date", typeof date, date)

        return (
            <Modal width={900} visible={visibleQuatation} onOk={this.handleSubmit} onCancel={this.handleCancel} className="quatationModal">
                <Form>
                    <div id="quatation">
                        <div id="head">
                            <div className="left">
                                <img src="/img/logo.png" alt="logo" />
                                <div className="col2">
                                    <p>เสนอ</p>
                                    {website.address && (
                                        <div>
                                            <p>{website.address.name}</p>
                                            <p>{website.address.address}</p>
                                        </div>
                                    )}
                                </div>
                                <div className="col2">
                                    <p>ผู้ติดต่อ</p>
                                    <div>
                                        <p>{profile.name}</p>
                                        <p>{profile.address}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="right">
                                <h1>ใบเสนอราคา</h1>
                                <div className="col2">
                                    <p>เลขที่ : </p>
                                    <FormItem>
                                        {getFieldDecorator("quatation_number", {
                                            rules: [{required: true, message: "Please enter quatation number"}],
                                            initialValue: currentData.quatation_number || parseInt(website.quatation_number) + 1
                                        })(<Input placeholder="Auto generate"  />)}
                                    </FormItem>
                                </div>
                                <div className="col2">
                                    <p>วันที่ : </p>
                                    <FormItem>
                                        {getFieldDecorator("date", {
                                            rules: [{required: true, message: "Please enter date"}],
                                            initialValue: currentData.date ? moment(currentData.date, "YYYY-MM-DD") : ""
                                        })(<DatePicker placeholder={currentData.date === null ? "Invalid date" : moment(new Date()).format("YYYY-MM-DD")} />)}
                                    </FormItem>
                                </div>
                                {date && <p className="date-thai"><span>{typeof date === "object" ? moment(date).format("D MMMM YYYY") : date}</span></p>}
                            </div>
                        </div>
                        <div id="table">
                            <div id="rowOfTable">
                                <InputNumber min={0} defaultValue={currentTableRow.length} onBlur={this.handleBlur} />
                            </div>
                            <table>
                                <thead>
                                    <tr>
                                        <th>บริการ</th>
                                        <th>หน่วย</th>
                                        <th>ราคาต่อหน่วย</th>
                                        <th>จำนวนเงิน(บาท)</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td colSpan="4">
                                            <FormItem>
                                                {getFieldDecorator("name", {
                                                    rules: [{required: true, message: "Please enter quatation title"}],
                                                    initialValue: currentData.name
                                                })(<TextArea placeholder="Title" />)}
                                            </FormItem>
                                        </td>
                                    </tr>
                                    {currentTableRow.map((value, key) => {
                                        return (
                                            <tr key={key}>
                                                <td>
                                                    <FormItem>
                                                        {getFieldDecorator("topic[" + key + "]", {
                                                            rules: [{required: false}],
                                                            initialValue: topic[key]
                                                        })(<Input placeholder="Topic" />)}
                                                    </FormItem>
                                                    <FormItem>
                                                        {getFieldDecorator("estimate_time[" + key + "]", {
                                                            rules: [{required: false}],
                                                            initialValue: estimate_time[key]
                                                        })(<Input placeholder="Time estimate" />)}
                                                    </FormItem>
                                                    <div className="textEditor">
                                                        <FormItem>
                                                            {getFieldDecorator("description[" + key + "]", {
                                                                rules: [{required: false}],
                                                                initialValue: description[key]
                                                            })(<TextArea placeholder="Description" />)}
                                                        </FormItem>
                                                    </div>
                                                </td>
                                                <td>
                                                    <FormItem>
                                                        {getFieldDecorator("quantity[" + key + "]", {
                                                            rules: [{required: false}],
                                                            initialValue: quantity[key] || 1
                                                        })(<Input placeholder="1" />)}
                                                    </FormItem>
                                                </td>
                                                <td>
                                                    <FormItem>
                                                        {getFieldDecorator("price[" + key + "]", {
                                                            rules: [{required: false}],
                                                            initialValue: price[key]
                                                        })(<Input placeholder="0.00" onChange={(e) => this.setTotalPrice(e, key)} />)}
                                                    </FormItem>
                                                </td>
                                                <td>
                                                    <FormItem>
                                                        {getFieldDecorator("total[" + key + "]", {
                                                            rules: [{required: false}],
                                                            initialValue: total[key]
                                                        })(<Input placeholder="0.00" readOnly={true} />)}
                                                    </FormItem>
                                                </td>
                                            </tr>
                                        )
                                    })}
                                    <tr>
                                        <td>&nbsp;</td>
                                        <td colSpan="3">
                                            <div>
                                                <p>จำนวนเงินทั้งสิ้น</p>
                                                <div>
                                                    <b>{numeral(net).format("0,0")}</b>
                                                    {currentData.net && <p>({convertNumberToWords(net)})</p>}
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div id="terms">
                            <div>
                                <h2>เงื่อนไขการชำระเงิน</h2>
                                <FormItem>
                                    {getFieldDecorator("term_of_payment", {
                                        initialValue: currentData.term_of_payment ? currentData.term_of_payment : "1"
                                    })(<RadioGroup options={termOfPayments} />)}
                                </FormItem>
                            </div>
                            <div>
                                <h2>หมายเหตุ</h2>
                                <FormItem>
                                    {getFieldDecorator("note", {
                                        initialValue: currentData.note ? currentData.note.split("~") : ["0", "2", "3"]
                                    })(<CheckboxGroup options={quatationNote} />)}
                                </FormItem>
                            </div>
                        </div>
                        <div id="signature">
                            <table>
                                <tbody>
                                    <tr>
                                        <td>
                                            <p>เสนอราคาโดย</p>
                                        </td>
                                        <td>
                                            <p>อนุมัติโดย</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <p>{profile.prefix + profile.name}</p>
                                        </td>
                                        <td>
                                            <p>วันที่: </p>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        {currentData.websites_id && (
                            <div id="deleteQuatation">
                                <button type="button" className="btn-delete" onClick={this.delete}>
                                    Delete
                                </button>
                            </div>
                        )}
                    </div>
                </Form>
            </Modal>
        )
    }
}

FormQuatation.propTypes = {
    currentData: PropTypes.object.isRequired,
    handleState: PropTypes.func.isRequired,
    visibleQuatation: PropTypes.bool.isRequired,
    refetch: PropTypes.func.isRequired
}

const WrappedFormQuatation = Form.create()(FormQuatation)
export default WrappedFormQuatation
