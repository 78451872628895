import React from "react"
import axios from "axios"
import moment from "moment"
import numeral from "numeral"
import qs from "qs"
import $ from "jquery"
import queryString from "query-string"
import {Helmet} from "react-helmet"
import {message, Switch, Button} from "antd"

import {convertNumberToWords, getQuatationNumber} from "../../modules"
import {profile, apiUrl, termOfPayments, quatationNote} from "../../consts"

class Quatation extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            quatation: {},
            website: {},
            signatureStatus: false,
        }
    }

    pad = (n) => {
        return n < 10 ? "0" + n : n
    }

    handleChange = (status) => {
        this.setState({signatureStatus: status})
    }

    componentDidMount = () => {
        const {match} = this.props
        const id = match.params && match.params.id ? match.params.id : false
        const me = this
        if (id) {
            axios({
                method: "post",
                url: apiUrl + "quatation.php",
                headers: {"content-type": "application/x-www-form-urlencoded"},
                data: qs.stringify({action: "dataForPrint", id: id}),
            })
                .then((res) => {
                    if (res && res.data) {
                        me.setState({quatation: res.data.quatation, website: res.data.website})
                        me.forceUpdate()
                    } else {
                        message.error("Error !!!, See console log", 1)
                    }
                })
                .catch((err) => {
                    console.log("err", err)
                })
        }
        $('body').css({background: '#191919'})
    }

    classNames = (classes) => {
        return Object.entries(classes)
            .filter(([key, value]) => value)
            .map(([key, value]) => key)
            .join(" ")
    }

    render() {
        const {website, quatation, signatureStatus} = this.state
        const {location} = this.props
        if (!website.id || !quatation.id) return null
        const date = quatation.date.split("-")
        const search = queryString.parse(location.search)
        let showLogo = false
        let lang = "th"
        if (typeof search === "object") {
            showLogo = typeof search.logo !== "undefined" ? true : false
            if (typeof search.lang !== "undefined") {
                lang = search.lang === "en" ? "en" : "th"
            }
            if (typeof search.en !== "undefined") {
                lang = "en"
            }
        }
        let terms = termOfPayments[quatation.term_of_payment] ? termOfPayments[quatation.term_of_payment].label : false
        terms = terms ? terms.split("--") : []
        return (
            <div id="quatation" className="print quatation">
                <Helmet>
                    <title>{getQuatationNumber(quatation.date, quatation.quatation_number) + "@" + website.website.toLowerCase()}</title>
                </Helmet>
                <div id="head" className={this.classNames({logo: showLogo, en: lang === "en"})}>
                    <div className="left">
                        {showLogo && <img src="/img/logo_trim.png" alt="logo" />}
                        {website.address.name && <div className="col2">
                            <p>{lang === "en" ? "ชื่อผู้ติดต่อ" : "ชื่อผู้ติดต่อ"}</p>
                            <div>
                                <p>{website.address.name}</p>
                            </div>
                        </div>}
                        {website.address.business && <div className="col2">
                            <p>{lang === "en" ? "เสนอ" : "เสนอ"}</p>
                            <div>
                                <p>{website.address.business}</p>
                            </div>
                        </div>}
                        <div className="col2">
                            <p>{lang === "en" ? "Address" : "ที่อยู่"}</p>
                            <div>
                                <p className="nl2br" dangerouslySetInnerHTML={{__html: website.address.address}} />
                            </div>
                        </div>
                    </div>
                    <div className="right">
                        <h1>{lang === "en" ? "Quatation" : "ใบเสนอราคา"}</h1>
                        <div className="col2">
                            <p>{lang === "en" ? "No." : "เลขที่"} : </p>
                            <p>{getQuatationNumber(quatation.date, quatation.quatation_number)}</p>
                        </div>
                        <div className="col2">
                            <p>{lang === "en" ? "Date" : "วันที่"} : </p>
                            <p>
                                {moment()
                                    .date(date[2])
                                    .month(date[1] - 1)
                                    .year(parseInt(date[0]) + 543)
                                    .format("D MMMM YYYY")}
                            </p>
                        </div>
                    </div>
                </div>
                <div id="table">
                    <table>
                        <thead>
                            <tr>
                                <th>{lang === "en" ? "Description" : "บริการ"}</th>
                                <th>{lang === "en" ? "Unit" : "หน่วย"}</th>
                                <th>{lang === "en" ? "Price" : "ราคาต่อหน่วย"}</th>
                                <th>{lang === "en" ? "Amount" : "จำนวนเงิน(บาท)"}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr className="title">
                                <td colSpan="4">
                                    <p id="title" dangerouslySetInnerHTML={{__html: quatation.name}} />
                                </td>
                            </tr>
                            {quatation.detail.topic.map((value, key) => {
                                return (
                                    <tr key={key}>
                                        <td>
                                            <p className="topic" dangerouslySetInnerHTML={{__html: quatation.detail.estimate_time[key] && quatation.detail.estimate_time[key] !== "" ? value + "<span>(" + quatation.detail.estimate_time[key] + ")</span>" : value}} />
                                            <div className="textEditor" dangerouslySetInnerHTML={{__html: quatation.detail.description[key]}} />
                                        </td>
                                        <td>{numeral(quatation.detail.quantity[key] ? quatation.detail.quantity[key] : 0).format("0,0")}</td>
                                        <td>{numeral(quatation.detail.price[key] ? quatation.detail.price[key] : 0).format("0,0")}</td>
                                        <td>{numeral(quatation.detail.total[key] ? quatation.detail.total[key] : 0).format("0,0")}</td>
                                    </tr>
                                )
                            })}
                            <tr>
                                <td>&nbsp;</td>
                                <td colSpan="3">
                                    <div>
                                        <p>{lang === "en" ? "Total" : "จำนวนเงินทั้งสิ้น"}</p>
                                        <div>
                                            <b>{numeral(parseFloat(quatation.net)).format("0,0")}</b>
                                            {lang !== "en" && <p>({convertNumberToWords(quatation.net)})</p>}
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div id="terms">
                    <div>
                        <h2>{lang === "en" ? "Payment term" : "เงื่อนไขการชำระเงิน"}</h2>
                        {<ul>{terms.map((value, key) => value !== "" && <li key={key}>{value}</li>)}</ul>}
                    </div>
                    <div>
                        <h2>{lang === "en" ? "Note" : "หมายเหตุ"}</h2>
                        {
                            <ul>
                                {quatation.note.map((value, key) => (
                                    <li key={key}>{quatationNote[value].label}</li>
                                ))}
                            </ul>
                        }
                    </div>
                </div>
                <div id="signature">
                    <table>
                        <tbody>
                            <tr>
                                <td>
                                    <p>{lang === "en" ? "" : "เสนอราคาโดย"}</p>
                                    {signatureStatus && <img src="/img/signature.png" alt="signature" />}
                                    <Switch onChange={this.handleChange} checkedChildren={<p className="switch-p">Show signature</p>} unCheckedChildren={<p className="switch-p">Hidden signature</p>}  />
                                </td>
                                <td>
                                    <p>{lang === "en" ? "" : "อนุมัติโดย"}</p>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <p>{profile.prefix + profile.name}</p>
                                </td>
                                <td>
                                    <p>{lang === "en" ? "Date:" : "วันที่:"} </p>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <p id="confirm-quatation">ยืนยันใบเสนอราคา: ส่งเมลยืนยันที่ developer.koong@gmail.com</p>
                </div>
                <div className="text-center mt30 hidden-print">
                    <Button icon="file-pdf" onClick={window.print} className="btn-print">
                        Print
                    </Button>
                </div>
            </div>
        )
    }
}

export default Quatation
