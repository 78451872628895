import React from "react"
import {Link} from "react-router-dom"
import axios from "axios"
import qs from "qs"
import {Table, Icon, message} from "antd"
import {apiUrl} from "../../consts"
import FormAddress from "./FormAddress"

class CustomerAddress extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            visibleAddress: false,
            dataSource: [],
            currentData: {},
            customer: {},
            columns: [
                {
                    title: "Name",
                    dataIndex: "name",
                    key: "name"
                },
                {
                    title: "Address",
                    dataIndex: "address",
                    key: "address"
                },
                {
                    title: "Action",
                    dataIndex: "action",
                    key: "action",
                    render: (text, record) => {
                        return (
                            <div>
                                <Icon type="form" className="mr5" onClick={(e) => this.modalEditAddress(record)} />
                                <Icon type="delete" onClick={(e) => this.delete(record)} />
                            </div>
                        )
                    }
                }
            ]
        }
    }

    refetch = () => {
        const {match} = this.props
        const {id} = match.params
        axios({
            method: "post",
            url: apiUrl + "customer.php",
            headers: {"content-type": "application/x-www-form-urlencoded"},
            data: qs.stringify({action: "getAddress", id: id})
        }).then((res) => {
            if (res && res.data) {
                this.setState({dataSource: res.data.address, customer: res.data.customer})
            } else {
                message.error("Error !!!, See console log", 1)
                console.log("res", res)
            }
        })
    }

    delete = (data) => {
        if (window.confirm("Are you sure to delete ?")) {
            axios({
                method: "post",
                url: apiUrl + "customer.php",
                headers: {"content-type": "application/x-www-form-urlencoded"},
                data: qs.stringify({action: "deleteAddress", id: data.id})
            }).then((res) => {
                if (res && res.data) {
                    this.refetch()
                } else {
                    message.error("Error !!!, See console log", 1)
                    console.log("res", res)
                }
            })
        }
    }

    modalEditAddress = (data = {}) => {
        this.setState({visibleAddress: true, currentData: data})
    }

    handleState = (handleState) => {
        this.setState(handleState)
    }

    componentDidMount = () => {
        this.refetch()
    }

    render() {
        const {match} = this.props
        const {dataSource, visibleAddress, currentData, columns, customer} = this.state

        return (
            <div id="container">
                <div id="top-bar">
                    <button className="btn-add" onClick={(e) => this.modalEditAddress()}>
                        <Icon type="plus" />
                        <span>New address</span>
                    </button>
                </div>
                <p className="customerName">{customer.name}</p>
                <Table pagination={false} dataSource={dataSource} id="customer-table" size="middle" bordered columns={columns} rowKey={(record) => record.key} />
                {visibleAddress && <FormAddress customers_id={match.params.id} visibleAddress={visibleAddress} refetch={this.refetch} currentData={currentData} handleState={this.handleState} />}
                <button className="pl15 pr15 mt10">
                    <Link to="/customers">Back</Link>
                </button>
            </div>
        )
    }
}

export default CustomerAddress
