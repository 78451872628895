import React from "react"
import PropTypes from "prop-types"
import axios from "axios"
import qs from "qs"
import moment from "moment"
import {Form, Modal, Input, Select, message, DatePicker} from "antd"
import {status, apiUrl} from "../../consts"
const FormItem = Form.Item
const Option = Select.Option

class FormWebsite extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            customerAddress: [],
            customerAddressId: false
        }
    }
    handleChange = (customers_id, e, customerAddressId = null) => {
        const data = {
            action: "getCustomerAddress",
            customers_id: customers_id
        }
        axios({
            method: "post",
            url: apiUrl + "website.php",
            headers: {"content-type": "application/x-www-form-urlencoded"},
            data: qs.stringify(data)
        }).then((res) => {
            if (res && res.data) {
                if (customerAddressId === null && res.data[0]) {
                    customerAddressId = res.data[0].id
                }
                this.setState({customerAddress: res.data, customerAddressId})
            } else {
                message.error("Error !!!, See console log", 1)
                console.log("res", res)
            }
        })
    }
    handleCancel = (e) => {
        this.props.handleState({visibleWebsite: false})
    }
    handleSubmit = (e) => {
        e.preventDefault()
        const {currentData, action, handleState} = this.props
        this.props.form.validateFields((err, values) => {
            if (!err) {
                const upd = {
                    id: currentData && currentData.id ? currentData.id : 0,
                    action: action,
                    ...values,
                    domain_expired: values.domain_expired ? moment(values.domain_expired).format("YYYY-MM-DD") : null,
                    host_expired: values.host_expired ? moment(values.host_expired).format("YYYY-MM-DD") : null
                }
                axios({
                    method: "post",
                    url: apiUrl + "website.php",
                    headers: {"content-type": "application/x-www-form-urlencoded"},
                    data: qs.stringify(upd)
                }).then((res) => {
                    if (res && res.data) {
                        message.success("Success !!!", 1)
                        const data = res.data && typeof res.data === "object" && res.data[0] ? res.data : []
                        handleState({dataSource: data, visibleWebsite: false, currentData: {}})
                    } else {
                        message.error("Error !!!, See console log", 1)
                        console.log("res", res)
                    }
                })
            }
        })
    }

    componentDidMount = () => {
        const {currentData} = this.props
        if (currentData && currentData.customers_id && currentData.customer_address_id) {
            this.handleChange(currentData.customers_id, "", currentData.customer_address_id)
        }
    }

    render() {
        const {customerAddress, customerAddressId} = this.state
        const {currentData, visibleWebsite, customers} = this.props
        const {getFieldDecorator} = this.props.form

        return (
            <Modal title={currentData.website} visible={visibleWebsite} onOk={this.handleSubmit} onCancel={this.handleCancel}>
                <Form>
                    <FormItem label="Customer">
                        {getFieldDecorator("customers_id", {
                            rules: [{required: true, message: "Please select customer!"}],
                            initialValue: currentData.customers_id
                        })(
                            <Select
                                placeholder="Customer"
                                showSearch
                                optionFilterProp="children"
                                onChange={this.handleChange}
                                filterOption={(input, option) => {
                                    if (typeof option.props.children !== "object") {
                                        return option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                    return false
                                }}
                            >
                                {customers.map((value, key) => (
                                    <Option key={key} value={value.id}>
                                        {value.name}
                                    </Option>
                                ))}
                            </Select>
                        )}
                    </FormItem>
                    <FormItem label="Customer address">
                        {getFieldDecorator("customer_address_id", {
                            rules: [{required: false}],
                            initialValue: customerAddressId !== false ? customerAddressId : currentData.customer_address_id
                        })(
                            <Select placeholder="Customer address">
                                {customerAddress.map((value, key) => (
                                    <Option key={key} value={value.id}>
                                        {value.name}
                                        {value.address.length > 1 ? " ( " + value.address + " )" : ""}
                                    </Option>
                                ))}
                            </Select>
                        )}
                    </FormItem>
                    <FormItem label="Website">
                        {getFieldDecorator("website", {
                            rules: [{required: false}],
                            initialValue: currentData.website
                        })(<Input placeholder="Website name" />)}
                    </FormItem>
                    <FormItem label="Domain">
                        {getFieldDecorator("domain", {
                            rules: [{required: false}],
                            initialValue: currentData.domain
                        })(<Input placeholder="Domain name" />)}
                    </FormItem>
                    <div class="col-2">
                        <FormItem label="Domain expired">
                            {getFieldDecorator("domain_expired", {
                                rules: [{required: false, message: "Please enter date"}],
                                initialValue: currentData.domain_expired ? moment(currentData.domain_expired, "YYYY-MM-DD") : ""
                            })(<DatePicker />)}
                        </FormItem>
                        <FormItem label="Price">
                            {getFieldDecorator("domain_price", {
                                rules: [{required: false, message: "Please enter price"}],
                                initialValue: currentData.domain_price || 0
                            })(<Input />)}
                        </FormItem>
                    </div>
                    <div className="col-2">
                    <FormItem label="Host expired">
                        {getFieldDecorator("host_expired", {
                            rules: [{required: false, message: "Please enter date"}],
                            initialValue: currentData.host_expired ? moment(currentData.host_expired, "YYYY-MM-DD") : ""
                        })(<DatePicker />)}
                    </FormItem>
                    <FormItem label="Price">
                        {getFieldDecorator("host_price", {
                            rules: [{required: false, message: "Please enter price"}],
                            initialValue: currentData.host_price || 0
                        })(<Input />)}
                    </FormItem>
                    </div>
                    <FormItem label="Status">
                        {getFieldDecorator("status", {
                            rules: [{required: true, message: "Please select current status!"}],
                            initialValue: currentData.status ? currentData.status : "open"
                        })(
                            <Select placeholder="Status" showSearch optionFilterProp="children" filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                {Object.keys(status).map((value) => (
                                    <Option key={value} value={value}>
                                        {status[value]}
                                    </Option>
                                ))}
                            </Select>
                        )}
                    </FormItem>
                </Form>
            </Modal>
        )
    }
}

FormWebsite.propTypes = {
    customers: PropTypes.array.isRequired,
    visibleWebsite: PropTypes.bool.isRequired,
    currentData: PropTypes.object.isRequired,
    action: PropTypes.string.isRequired,
    handleState: PropTypes.func.isRequired
}

const WrappedFormWebsite = Form.create()(FormWebsite)
export default WrappedFormWebsite
