import React from "react"
import axios from "axios"
import moment from "moment"
import numeral from "numeral"
import qs from "qs"
import $ from "jquery"
import {Helmet} from "react-helmet"
import {message, Switch, Button, DatePicker, Form} from "antd"
import queryString from "query-string"

import {getHostingNumber} from "../../modules"
import {profile, apiUrl} from "../../consts"

class Hosting extends React.Component {
    state = {
        website: {},
        signatureStatus: false,
    }

    handleChange = (status) => {
        this.setState({signatureStatus: status})
    }

    componentDidMount = () => {
        const {match} = this.props
        const id = match.params && match.params.id ? match.params.id : false
        if (id) {
            axios({
                method: "post",
                url: apiUrl + "hosting.php",
                headers: {"content-type": "application/x-www-form-urlencoded"},
                data: qs.stringify({action: "dataForPrint", id: id}),
            }).then((res) => {
                if (res && res.data) {
                    res.data.domain_price = parseFloat(res.data.domain_price)
                    res.data.host_price = parseFloat(res.data.host_price)
                    this.setState({website: res.data})
                } else {
                    message.error("Error !!!, See console log", 1)
                    console.log("res", res)
                }
            })
        }
        $("body").css({background: "#191919"})
    }

    render() {
        const {website, signatureStatus} = this.state
        const {form, location} = this.props
        const {getFieldDecorator} = form
        if (!website || !website.id) return false

        const d = new Date()
        const year = d.getFullYear()
        const month = d.getMonth()
        const day = d.getDate()
        const newDate = form.getFieldValue("date") || new Date(year + 543, month, day)
        const date = moment(newDate, "DD MMM YYYY")
        const search = queryString.parse(location.search)

        const total = parseFloat(website.host_price) + parseFloat(website.domain_price)

        let type = {}
        const types = {
            all: ["HOSTING/DOMAIN NAME", "Hosting และ Domain name", website.domain_expired],
            domain: ["DOMAIN NAME", "Domain name", website.domain_expired],
            hosting: ["HOSTING", "Hosting", website.host_expired],
        }
        if (website.domain_price > 0 && website.host_price > 0) {
            type = types.all
        } else if (website.domain_price > 0 && website.host_price === 0) {
            type = types.domain
        } else if (website.domain_price === 0 && website.host_price > 0) {
            type = types.hosting
        } else {
            return "Invalid price"
        }

        let showLogo = false
        if (typeof search === "object") {
            showLogo = typeof search.logo !== "undefined" ? true : false
        }

        return (
            <div id="quatation" className="print hosting invoiceprint">
                <Helmet>
                    <title>{"service@" + website.website.toLowerCase()}</title>
                </Helmet>
                <div id="head" className={showLogo ? "logo" : ""}>
                    <div className="left">
                        {showLogo && <img src="/img/logo_trim.png" alt="logo" />}
                        {website.address.name && (
                            <div className="col2">
                                <p>ชื่อผู้ติดต่อ</p>
                                <div>
                                    <p>{website.address.name}</p>
                                </div>
                            </div>
                        )}
                        {website.address.business && (
                            <div className="col2">
                                <p>บริษัท</p>
                                <div>
                                    <p>{website.address.business}</p>
                                </div>
                            </div>
                        )}
                        <div className="col2">
                            <p>ที่อยู่</p>
                            <div>
                                <p className="nl2br" dangerouslySetInnerHTML={{__html: website.address.address}} />
                            </div>
                        </div>
                    </div>
                    <div className="right">
                        <h1>
                            ใบแจ้งค่าบริการ <span>{type[0]}</span>
                        </h1>
                        <div className="col2">
                            <p>เลขที่ : </p>
                            <p>{getHostingNumber(website.date, 1)}</p>
                        </div>
                        <div className="col2">
                            <p>วันที่ : </p>
                            <p>
                                {getFieldDecorator("date", {
                                    rules: [{required: true, message: "Please enter date"}],
                                    initialValue: date,
                                })(<DatePicker format="D MMMM YYYY" />)}
                            </p>
                        </div>
                    </div>
                </div>
                <div id="table">
                    <table>
                        <thead>
                            <tr>
                                <th>บริการ</th>
                                <th>ยอดที่ต้องชำระทั้งสิ้น</th>
                                <th>โปรดชำระก่อนวันที่</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr className="title">
                                <td>
                                    <p id="title">
                                        {type[1]}
                                        <br />
                                        Website: {website.domain}
                                    </p>
                                </td>
                                <td>{numeral(total).format("0,0.00")}</td>
                                <td>{moment(type[2]).add(543, "Y").format("D MMM YYYY")}</td>
                            </tr>
                            <tr>
                                <td colSpan="3" className="table-desc">
                                    <p>รายละเอียดบริการ</p>
                                    <div>
                                        <table>
                                            <body>
                                                <tr>
                                                    <td>รายการ</td>
                                                    <td>จำนวนเงิน (บาท)</td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        ค่าบริการ {type[1]} รอบปัจจุบัน
                                                        <br />
                                                        (วันที่ {moment(type[2]).add(543, "Y").format("DD/MM/YYYY") + " - " + moment(type[2]).add(544, "Y").format("DD/MM/YYYY")})
                                                    </td>
                                                    <td>{numeral(total).format("0,0.00")}</td>
                                                </tr>
                                                <tr>
                                                    <td>ยอดค้างชำระ</td>
                                                    <td>0.00</td>
                                                </tr>
                                                <tr>
                                                    <td>รวมค่าบริการที่ต้องชำระทั้งสิ้น</td>
                                                    <td>{numeral(total).format("0,0.00")}</td>
                                                </tr>
                                            </body>
                                        </table>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div id="terms">
                    <div>
                        <h2>หมายเหตุ</h2>
                        <ul>
                            <li>หักภาษี ณ ที่จ่าย 3%</li>
                        </ul>
                    </div>
                    <div>
                        <h2>ช่องทางการชำระเงิน</h2>
                        <ul className="banks">
                            {profile.banks.map((value, key) => {
                                return (
                                    <li key={key} className="bank">
                                        <img src={"/img/" + value.logo} alt={value.logo} />
                                        <div>
                                            <p>ชื่อบัญชี {profile.name} (Noppasin Lekteds)</p>
                                            <p>
                                                หมายเลขบัญชี {value.id}, ธนาคาร {value.name} สาขา {value.branch}
                                            </p>
                                        </div>
                                    </li>
                                )
                            })}
                        </ul>
                    </div>
                </div>
                <div id="signature">
                    <table>
                        <tbody>
                            <tr>
                                <td>
                                    <p>ลงชื่อ</p>
                                    {signatureStatus && <img src="/img/signature.png" alt="" />}
                                    <Switch onChange={this.handleChange} checkedChildren={<p className="switch-p">Show signature</p>} unCheckedChildren={<p className="switch-p">Hidden signature</p>}  />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <p>({profile.prefix + profile.name})</p>
                                    <p>{moment(date).format("DD/MM/YYYY")}</p>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className="text-center mt30 hidden-print">
                    <Button icon="file-pdf" onClick={window.print} className="btn-print">
                        Print
                    </Button>
                </div>
            </div>
        )
    }
}

const WrappedHosting = Form.create()(Hosting)
export default WrappedHosting
